
import { computed, defineComponent, PropType } from 'vue';
import { IntertextDetail } from '@/interfaces/IntertextDetail';
import ArticleSection from '@/components/global/ArticleSection.vue';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import { intertextWeb } from '@/api/web';
import { isLoaded } from '@/interfaces/RemoteData';
import LiteratureInfoTable from '@/components/intertext/LiteratureInfoTable.vue';
import InfoTable from './InfoTable.vue';
import HowToQuote from '../global/HowToQuote.vue';

export default defineComponent({
  components: {
    InfoTable,
    ArticleContainer,
    ArticleSection,
    HowToQuote,
    LiteratureInfoTable,
  },
  props: {
    intertext: Object as PropType<IntertextDetail>,
    precitation: String,
  },
  setup() {
    return {
      intertextWeb,
      isLoaded,
    };
  },
});
