import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-xl block m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageHolder = _resolveComponent("ImageHolder")!
  const _component_ScrollCard = _resolveComponent("ScrollCard")!

  return (_openBlock(), _createBlock(_component_ScrollCard, {
    title: _ctx.$tc('intertext.images', _ctx.images.length)
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: image.url,
          class: _normalizeClass(["p-2", index > 0 ? 'pt-12' : ''])
        }, [
          _createVNode(_component_ImageHolder, {
            imageUrl: image.url,
            imageDescription: image.credits,
            imageRatio: 2,
            class: "mx-auto",
            style: {"min-height":"24rem"}
          }, null, 8, ["imageUrl", "imageDescription"]),
          _createElementVNode("p", _hoisted_1, _toDisplayString(image.credits), 1)
        ], 2))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"]))
}