import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Article = _resolveComponent("Article")!
  const _component_Materials = _resolveComponent("Materials")!
  const _component_References = _resolveComponent("References")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Article, {
      intertext: _ctx.intertext,
      precitation: _ctx.precitation
    }, null, 8, ["intertext", "precitation"]),
    _createVNode(_component_Materials, { intertext: _ctx.intertext }, null, 8, ["intertext"]),
    _createVNode(_component_References),
    (_ctx.isLoaded(_ctx.intertextWeb))
      ? (_openBlock(), _createBlock(_component_HowToQuote, {
          key: 0,
          citation: _ctx.intertextWeb.payload.how_to_cite,
          precitation: _ctx.precitation
        }, null, 8, ["citation", "precitation"]))
      : _createCommentVNode("", true)
  ], 64))
}