import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_CommentaryMentions = _resolveComponent("CommentaryMentions")!
  const _component_Metadata = _resolveComponent("Metadata")!
  const _component_QuranPassages = _resolveComponent("QuranPassages")!
  const _component_TwoItems = _resolveComponent("TwoItems")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('intertext.references')
    }, null, 8, ["title"]),
    _createVNode(_component_TwoItems, { class: "showLinks" }, {
      first: _withCtx(() => [
        _createVNode(_component_CommentaryMentions),
        _createVNode(_component_Metadata)
      ]),
      second: _withCtx(() => [
        _createVNode(_component_QuranPassages)
      ]),
      _: 1
    })
  ], 64))
}