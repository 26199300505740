
import { defineComponent, PropType } from 'vue';
import { IntertextImage } from '@/interfaces/IntertextImage';
import ImageHolder from '@/components/global/ImageHolder.vue';
import ScrollCard from '../global/ScrollCard.vue';

export default defineComponent({
  components: {
    ScrollCard,
    ImageHolder,
  },
  props: { images: Object as PropType<IntertextImage> },
});
