import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-6" }
const _hoisted_2 = { class: "pt-3" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "" }
const _hoisted_5 = {
  key: 0,
  class: "py-2"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoTable = _resolveComponent("InfoTable")!
  const _component_ArticleSection = _resolveComponent("ArticleSection")!
  const _component_LiteratureInfoTable = _resolveComponent("LiteratureInfoTable")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createBlock(_component_ArticleContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.intertext.title), 1),
      _createVNode(_component_InfoTable, { intertext: _ctx.intertext }, null, 8, ["intertext"]),
      _createVNode(_component_ArticleSection, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(`${_ctx.$t('intertext.editor')}: ${_ctx.intertext.entry_author}`), 1),
          _createElementVNode("div", {
            innerHTML: _ctx.intertext.notes,
            class: "showLinks spaceParagraphs"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createVNode(_component_ArticleSection, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('manuscripts.literature')), 1),
          _createVNode(_component_LiteratureInfoTable, { intertext: _ctx.intertext }, null, 8, ["intertext"]),
          (_ctx.intertext.literature)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.$t('intertext.additional_sources')), 1))
            : _createCommentVNode("", true),
          (_ctx.intertext.literature)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                innerHTML: _ctx.intertext.literature,
                class: "showLinks spaceParagraphsSmall"
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ArticleSection, null, {
        default: _withCtx(() => [
          (_ctx.isLoaded(_ctx.intertextWeb))
            ? (_openBlock(), _createBlock(_component_HowToQuote, {
                key: 0,
                citation: _ctx.intertextWeb.payload.how_to_cite,
                precitation: _ctx.precitation
              }, null, 8, ["citation", "precitation"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}