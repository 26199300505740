
import { defineComponent } from 'vue';
import { payloadTooLarge } from '@/interfaces/RemoteData';
import { verseRange } from '@/api/verses';

export default defineComponent({
  setup() {
    return {
      verseRange, payloadTooLarge,
    };
  },
});
