import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollCard = _resolveComponent("ScrollCard")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toDisplay, (t, language) => {
    return (_openBlock(), _createBlock(_component_ScrollCard, {
      key: language,
      title: _ctx.$t('intertext.translation', { language }),
      htmlContent: t,
      informationText: _ctx.germanSource
    }, null, 8, ["title", "htmlContent", "informationText"]))
  }), 128))
}