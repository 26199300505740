import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "pt-5" }
const _hoisted_2 = { class: "pl-1" }
const _hoisted_3 = { class: "arabic text-2xl" }
const _hoisted_4 = { class: "pt-5" }
const _hoisted_5 = { class: "pr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.payloadTooLarge(_ctx.verseRange))
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createTextVNode(_toDisplayString(_ctx.$t('global.verse_range_too_large')), 1)
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('print_edition.arabic_header')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verseRange.payload, (v) => {
          return (_openBlock(), _createElementBlock("p", {
            key: v.arabic,
            class: "arabic",
            dir: "rtl"
          }, [
            _createElementVNode("sup", _hoisted_2, _toDisplayString(`${v.sura}:${v.verse}`), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(v.arabic), 1)
          ]))
        }), 128)),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('print_edition.translation_header')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verseRange.payload, (v) => {
          return (_openBlock(), _createElementBlock("p", {
            key: v.translation
          }, [
            _createElementVNode("sup", _hoisted_5, _toDisplayString(`${v.sura}:${v.verse}`), 1),
            _createElementVNode("span", null, _toDisplayString(v.translation), 1)
          ]))
        }), 128))
      ], 64))
}