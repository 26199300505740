
import { defineComponent, PropType } from 'vue';
import ScrollCard from '@/components/global/ScrollCard.vue';
import SectionHeader from '@/components/global/SectionHeader.vue';
import Translations from '@/components/intertext/Translations.vue';
import IntertextImages from '@/components/intertext/Images.vue';
import TwoItems from '@/components/global/TwoItems.vue';
import { intertextWeb } from '@/api/web';
import { IntertextDetail } from '@/interfaces/IntertextDetail';

export default defineComponent({
  components: {
    SectionHeader,
    TwoItems,
    IntertextImages,
    ScrollCard,
    Translations,
  },
  props: { intertext: Object as PropType<IntertextDetail> },
  setup() {
    return { intertextWeb };
  },
});
