import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_IntertextImages = _resolveComponent("IntertextImages")!
  const _component_ScrollCard = _resolveComponent("ScrollCard")!
  const _component_Translations = _resolveComponent("Translations")!
  const _component_TwoItems = _resolveComponent("TwoItems")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.intertext.title
    }, null, 8, ["title"]),
    _createVNode(_component_TwoItems, null, {
      first: _withCtx(() => [
        (_ctx.intertext.images.length > 0)
          ? (_openBlock(), _createBlock(_component_IntertextImages, {
              key: 0,
              images: _ctx.intertext.images
            }, null, 8, ["images"]))
          : _createCommentVNode("", true),
        (_ctx.intertext.content)
          ? (_openBlock(), _createBlock(_component_ScrollCard, {
              key: 1,
              title: _ctx.$t('intertext.original_language'),
              informationText: _ctx.intertextWeb.payload?.original_info,
              htmlContent: _ctx.intertext.content,
              direction: _ctx.intertext.content_direction,
              class: "text-wrap text-xl"
            }, null, 8, ["title", "informationText", "htmlContent", "direction"]))
          : _createCommentVNode("", true),
        (_ctx.intertext.transcription)
          ? (_openBlock(), _createBlock(_component_ScrollCard, {
              key: 2,
              title: _ctx.$t('print_edition.transcription_header'),
              informationText: _ctx.intertextWeb.payload?.transcription_info,
              htmlContent: _ctx.intertext.transcription
            }, null, 8, ["title", "informationText", "htmlContent"]))
          : _createCommentVNode("", true)
      ]),
      second: _withCtx(() => [
        _createVNode(_component_Translations, {
          translations: _ctx.intertext.translations,
          germanSource: _ctx.intertext.translation_source
        }, null, 8, ["translations", "germanSource"])
      ]),
      _: 1
    })
  ], 64))
}