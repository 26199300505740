
import { defineComponent } from 'vue';
import { intertextDetail } from '@/api/intertext';
import Card from '@/components/global/Card.vue';
import { intertextWeb } from '@/api/web';

export default defineComponent({
  components: { Card },
  setup() {
    return {
      intertextDetail,
      intertextWeb,
    };
  },
});
