import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "pt-10"
}
const _hoisted_3 = { class: "text-center py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IntertextFull = _resolveComponent("IntertextFull")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_RemoteContent = _resolveComponent("RemoteContent")!

  return (_openBlock(), _createBlock(_component_RemoteContent, { data: _ctx.intertextDetail }, {
    default: _withCtx(() => [
      (_ctx.intertextDetail.payload?.passages.find(_ctx.verseInRange(_ctx.verse)) || _ctx.showIntertextAnyway)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_IntertextFull, {
              intertext: _ctx.intertextDetail.payload
            }, null, 8, ["intertext"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('intertext.no_matching_verse', { id: _ctx.$route.params.id })), 1),
            _createVNode(_component_PrimaryButton, {
              class: "text-black w-72 m-auto block",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showIntertextAnyway = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('intertext.show_it_anyway')), 1)
              ]),
              _: 1
            })
          ]))
    ]),
    _: 1
  }, 8, ["data"]))
}