import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.$t('intertext.commentaries'),
    informationText: _ctx.intertextWeb.payload?.commentary_info
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intertextDetail.payload?.sura_commentaries, (s) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: s,
          to: { name: 'VerseCommentary', params: { sura: s, verse: 1 } },
          class: "block p-3"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('intertext.commentary', { sura: s })), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128)),
      (_ctx.intertextDetail.payload?.sura_commentaries.length === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('intertext.commentary_no_mention')), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "informationText"]))
}